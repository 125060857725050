import { useTrackAnnotationRedoClicked, useTrackAnnotationUndoClicked } from '@air/analytics';
import { AnnotationOutline, Close, Redo, Undo } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import classNames from 'classnames';
import { memo, useCallback } from 'react';

import { PencilAnnotationUtils } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/PencilUtils/PencilAnnotationUtils';
import { AnnotationIconButton } from '~/components/AssetModal/AssetModalFooter/AnnotationsToggle/AnnotateUtilsPanel/shared/AnnotationIconButton';
import {
  ANNOTATION_UTILS_PANEL,
  BOX_ANNOTATION_BUTTON,
  CLOSE_ANNOTATION_PANEL_BUTTON,
  REDO_ANNOTATION_BTN,
  UNDO_ANNOTATION_BTN,
} from '~/constants/testIDs';
import { useAnnotationContext, useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import {
  annotationTypeSelector,
  canRedoAnnotationSelector,
  canUndoAnnotationSelector,
} from '~/providers/AnnotationProvider/annotationProviderUtils';

export interface AnnotationUtilsPanelProps {
  onCloseClick: () => void;
}

export const AnnotationUtilsPanel = memo(({ onCloseClick }: AnnotationUtilsPanelProps) => {
  const { setAnnotationType, undo, redo } = useAnnotationContext();
  const annotationType = useAnnotationContextSelector(annotationTypeSelector);
  const { trackAnnotationRedoClicked } = useTrackAnnotationRedoClicked();
  const { trackAnnotationUndoClicked } = useTrackAnnotationUndoClicked();
  const canUndo = useAnnotationContextSelector(canUndoAnnotationSelector) && annotationType === 'pencil';
  const canRedo = useAnnotationContextSelector(canRedoAnnotationSelector) && annotationType === 'pencil';

  const setBoxAnnotationType = useCallback(() => setAnnotationType('box'), [setAnnotationType]);

  const handleRedoClick = useCallback(() => {
    redo();
    trackAnnotationRedoClicked();
  }, [redo, trackAnnotationRedoClicked]);

  const handleUndoClick = useCallback(() => {
    undo();
    trackAnnotationUndoClicked();
  }, [undo, trackAnnotationUndoClicked]);

  return (
    <div className="flex items-center gap-2 rounded-lg bg-black px-3 py-2" data-testid={ANNOTATION_UTILS_PANEL}>
      <AnnotationIconButton
        className={classNames(annotationType === 'box' ? 'bg-jay-200/25 text-jay-200 hover:text-jay-200' : undefined)}
        data-testid={BOX_ANNOTATION_BUTTON}
        label="Annotate"
        onClick={setBoxAnnotationType}
      >
        <AnnotationOutline className="size-4" />
      </AnnotationIconButton>

      <PencilAnnotationUtils />

      <IconButton
        appearance="ghost"
        className="text-pigeon-100 hover:bg-pigeon-100/15 hover:text-pigeon-100"
        color="grey"
        disabled={!canUndo}
        data-testid={UNDO_ANNOTATION_BTN}
        size="small"
        label="Undo"
        onClick={handleUndoClick}
      >
        <Undo className="size-4" />
      </IconButton>

      <IconButton
        appearance="ghost"
        className="text-pigeon-100 hover:bg-pigeon-100/15 hover:text-pigeon-100"
        color="grey"
        data-testid={REDO_ANNOTATION_BTN}
        disabled={!canRedo}
        label="Redo"
        onClick={handleRedoClick}
        size="small"
      >
        <Redo className="size-4" />
      </IconButton>

      <IconButton
        appearance="ghost"
        className="text-pigeon-100 hover:bg-pigeon-100/15 hover:text-pigeon-100"
        color="grey"
        data-testid={CLOSE_ANNOTATION_PANEL_BUTTON}
        label="Close annotation"
        onClick={onCloseClick}
        size="small"
      >
        <Close className="size-4" />
      </IconButton>
    </div>
  );
});

AnnotationUtilsPanel.displayName = 'AnnotationUtilsPanel';
