import { useTrackClosedVersionsTray, useTrackOpenedVersionsTray } from '@air/analytics';
import { Stack, TriangleDown, TriangleUp } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';

import { VERSION_SNIPPET_DROPDOWN_BUTTON } from '~/constants/testIDs';
import { centralizedClipVersionNumberSelector } from '~/store/centralizedClip/selectors';

export interface ToggleVersionsPanelButtonProps {
  isVersionsOpen: boolean;
  setIsVersionsOpen: (isOpen: boolean) => void;
}

export const ToggleVersionsPanelButton = memo(
  ({ isVersionsOpen, setIsVersionsOpen }: ToggleVersionsPanelButtonProps) => {
    const versionNumber = useSelector(centralizedClipVersionNumberSelector);
    // After uploading a new version, the version # gets set to null momentarily
    // this prevents that.
    const previousVersionNumber = usePrevious(versionNumber);
    const { trackOpenedVersionsTray } = useTrackOpenedVersionsTray();
    const { trackClosedVersionsTray } = useTrackClosedVersionsTray();

    return (
      <Button
        appearance={isVersionsOpen ? 'outline' : 'ghost'}
        color="grey"
        data-testid={VERSION_SNIPPET_DROPDOWN_BUTTON}
        onClick={() => {
          setIsVersionsOpen(!isVersionsOpen);
          if (isVersionsOpen) {
            trackClosedVersionsTray();
          } else {
            trackOpenedVersionsTray();
          }
        }}
        prefix={<Stack className="mr-2 size-4" />}
        suffix={isVersionsOpen ? <TriangleDown className="ml-2 size-3" /> : <TriangleUp className="ml-2 size-3" />}
      >
        {`V${versionNumber || previousVersionNumber}`}
      </Button>
    );
  },
);

ToggleVersionsPanelButton.displayName = 'ToggleVersionsPanelButton';
