import { Clip } from '@air/api/types';
import { memo, ReactNode, useCallback, useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';

import { ClipDiscussion as ClipDiscussionType } from '~/components/Annotations/shared/types';
import { NEW_DISCUSSION_ID } from '~/components/Annotations/shared/useActiveDiscussionIdObserver';
import { DiscussionsLoadMoreItem } from '~/components/Discussions/components/DiscussionsLoadMoreItem';
import {
  DiscussionsVirtualizedList,
  DiscussionsVirtualizedListHandle,
  DiscussionsVirtualizedListProps,
} from '~/components/Discussions/components/DiscussionsVirtualizedList';
import {
  ClipDiscussion,
  ClipDiscussionProps,
  DiscussionSkeleton,
} from '~/components/Discussions/Discussion/ClipDiscussion';
import { CommentMenuProps } from '~/components/Discussions/Discussion/DiscussionComment/components/CommentMenu';
import { DiscussionCommentProps } from '~/components/Discussions/Discussion/DiscussionComment/DiscussionComment';
import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { DISCUSSIONS_LIST } from '~/constants/testIDs';

export interface DiscussionsListProps {
  discussions: ClipDiscussionType[];
  isLoading: boolean;
  /**
   * @see https://github.com/signavio/react-mentions
   */
  mentionsList: MentionsData[];
  onCommentDelete: CommentMenuProps['onDelete'];
  onCommentEdit: DiscussionCommentProps['onSave'];
  onToggleCommentResolve?: CommentMenuProps['onToggleResolve'];
  onCommentSubmit?: (comment: string, discussion: ClipDiscussionType, clipId: Clip['id']) => void;
  onSwitchVersion?: ClipDiscussionProps['onSwitchVersion'];
  shouldShowMentionsButton?: boolean;
  onAnnotatedCommentClick: ClipDiscussionProps['onAnnotatedCommentClick'];
  canReply?: boolean;
  emptyState?: ReactNode;

  hasNextPage: boolean;
  fetchNextPage: () => void;
  totalDiscussions: number;
}

export const DiscussionsList = memo(
  ({
    discussions,
    canReply = true,
    isLoading,
    onCommentDelete,
    onCommentEdit,
    onToggleCommentResolve,
    onCommentSubmit,
    mentionsList,
    onSwitchVersion,
    shouldShowMentionsButton,
    onAnnotatedCommentClick,
    emptyState,
    hasNextPage,
    fetchNextPage,
    totalDiscussions,
  }: DiscussionsListProps) => {
    const listRef = useRef<DiscussionsVirtualizedListHandle>(null);

    const previousDiscussionsTotal = usePrevious(totalDiscussions);

    useEffect(() => {
      if (previousDiscussionsTotal && totalDiscussions > previousDiscussionsTotal) {
        listRef.current?.scrollToBottom();
      }
    }, [previousDiscussionsTotal, totalDiscussions]);

    const renderComments = useCallback<DiscussionsVirtualizedListProps['children']>(
      ({ index }) => {
        if (hasNextPage && index === 0) {
          return (
            <DiscussionsLoadMoreItem
              scrollElementRef={listRef.current?.getScrollElement()}
              isLoading={isLoading}
              loadMore={fetchNextPage}
            />
          );
        } else {
          const itemIndex = hasNextPage ? index : index + 1;
          const discussion = discussions[discussions.length - itemIndex];
          return (
            <ClipDiscussion
              discussion={discussion}
              clipId={discussion.clipId}
              /**
               * We use `NEW_DISCUSSION_ID` for temporary comments in cache. However, since we use
               * the `discussion.id` as the key for the discussion, we need to append the index to
               * the key to avoid React from reusing the same DOM element for different discussions.
               */
              key={discussion.id === NEW_DISCUSSION_ID ? `${discussion.id}-${index}` : discussion.id}
              mentionsList={mentionsList}
              canReply={canReply}
              onDelete={onCommentDelete}
              onEdit={onCommentEdit}
              onToggleResolve={onToggleCommentResolve}
              onSubmit={onCommentSubmit}
              onSwitchVersion={onSwitchVersion}
              shouldShowMentionsButton={shouldShowMentionsButton}
              onAnnotatedCommentClick={onAnnotatedCommentClick}
            />
          );
        }
      },
      [
        canReply,
        discussions,
        fetchNextPage,
        hasNextPage,
        isLoading,
        mentionsList,
        onAnnotatedCommentClick,
        onCommentDelete,
        onCommentEdit,
        onCommentSubmit,
        onSwitchVersion,
        onToggleCommentResolve,
        shouldShowMentionsButton,
      ],
    );

    return (
      <div className="flex h-full overflow-y-auto">
        <div className="relative flex max-h-full w-full flex-1" data-testid={DISCUSSIONS_LIST}>
          {isLoading && !discussions.length ? (
            <div className="flex size-full flex-col justify-end px-5">
              <DiscussionSkeleton />
              <DiscussionSkeleton />
            </div>
          ) : !!discussions?.length ? (
            <DiscussionsVirtualizedList
              ref={listRef}
              itemsCount={hasNextPage ? discussions.length + 1 : discussions.length}
            >
              {renderComments}
            </DiscussionsVirtualizedList>
          ) : (
            emptyState
          )}
        </div>
      </div>
    );
  },
);

DiscussionsList.displayName = 'DiscussionsList';
