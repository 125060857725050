import { Sync as SyncIcon } from '@air/next-icons';
import { memo } from 'react';

export const ProcessingMessage = memo(() => {
  return (
    <div className="inset-x-0 top-0 flex w-full items-center bg-black py-2.5">
      <SyncIcon className="ml-4 mr-3 block size-5 animate-spin text-white" />
      <div className="text-14 text-white">This video is still processing. You may experience delayed playback.</div>
    </div>
  );
});

ProcessingMessage.displayName = 'ProcessingMessage';
