import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useSelector } from 'react-redux';

import {
  useDiscussionPanelContext,
  useDiscussionPanelContextSelector,
} from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { ASSET_MODAL_DISCUSSION_VERSION_FILTERS } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';
import { discussionsVersionFilterSelector } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextUtils';
import { centralizedClipVersionNumberSelector } from '~/store/centralizedClip/selectors';

export const CommentsVersionsDropdown = () => {
  const versionNumber = useSelector(centralizedClipVersionNumberSelector);
  const { setDiscussionsVersionFilter } = useDiscussionPanelContext();
  const discussionsVersionFilter = useDiscussionPanelContextSelector(discussionsVersionFilterSelector);

  return (
    <DropdownMenu
      trigger={
        <Button
          appearance="ghost"
          className="px-1.5"
          color="grey"
          data-testid="COMMENTS_VERSIONS_DROPDOWN"
          size="small"
          suffix={<TriangleDownIcon className="size-3" />}
        >
          {discussionsVersionFilter === ASSET_MODAL_DISCUSSION_VERSION_FILTERS.ALL
            ? 'All versions'
            : `Version ${versionNumber}`}
        </Button>
      }
    >
      {renderDropdownItems({
        options: [
          {
            id: 'all-versions',
            label: 'All versions',
            onSelect: () => setDiscussionsVersionFilter(ASSET_MODAL_DISCUSSION_VERSION_FILTERS.ALL),
            type: 'item',
          },
          {
            id: 'current-version',
            label: 'Current version',
            onSelect: () => setDiscussionsVersionFilter(ASSET_MODAL_DISCUSSION_VERSION_FILTERS.CURRENT),
            type: 'item',
          },
        ],
      })}
    </DropdownMenu>
  );
};
