import { useRect } from '@reach/rect';
import classNames from 'classnames';
import Router from 'next/router';
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { AnnotationsAvatarsContainer } from '~/components/Annotations/AnnotationsAvatarsContainer/AnnotationsAvatarsContainer';
import { AnnotationsKeyShortcuts } from '~/components/Annotations/components/AnnotationsKeyShortcuts';
import { BoxAnnotationLayer } from '~/components/Annotations/components/BoxAnnotationLayer';
import { PencilAnnotationLayer } from '~/components/Annotations/components/PencilAnnotationLayer';
import { getPageNumber, isPointAnnotation } from '~/components/Annotations/shared/annotationUtils';
import { annotationTooltipText } from '~/components/Annotations/shared/constants';
import { ClipDiscussion, FreeformAnnotation } from '~/components/Annotations/shared/types';
import { useFullscreenContext } from '~/components/AssetModal/shared/context/FullscreenContext';
import { AnnotationTooltip } from '~/components/AssetModal/Visualizer/Annotations/AnnotationTooltip';
import { useMouseTooltip } from '~/components/AssetModal/Visualizer/Annotations/useMouseTooltip';
import { BOUNDING_BOX_LAYER_Z_INDEX } from '~/components/BoundingBox/constants';
import { QueryParamNames } from '~/constants/search';
import { ANNOTATION_LAYER } from '~/constants/testIDs';
import { useAnnotationContext, useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import {
  activeAnnotationSelector,
  annotationColorSelector,
  annotationSizeSelector,
  annotationTypeSelector,
  isAnnotationsEnabledSelector,
  newAnnotationInfoSelector,
} from '~/providers/AnnotationProvider/annotationProviderUtils';
import { activeTimestampParamSelector } from '~/store/router/selectors';
import { isCypressRun, setQueryParams } from '~/utils/PathUtils';

export interface AnnotationsProps {
  pageNumber: number;
  keyShortcutsEnabled: boolean;
  canDrawAnnotation: boolean;
  width: number;
  height: number;
  topOffset?: number;
  onIsSelectingChange?: (isSelecting: boolean) => void;
  hideAnnotations?: boolean;
}

export const Annotations = memo(
  ({
    keyShortcutsEnabled,
    canDrawAnnotation,
    pageNumber,
    width,
    height,
    onIsSelectingChange,
    topOffset = 0,
    hideAnnotations,
  }: AnnotationsProps) => {
    const { isFullscreen } = useFullscreenContext();
    const containerRef = useRef<HTMLDivElement>(null);
    const annotationTooltipRef = useRef<HTMLDivElement | null>(null);

    const containerRect = useRect(containerRef);

    const annotationType = useAnnotationContextSelector(annotationTypeSelector);
    const newAnnotation = useAnnotationContextSelector(newAnnotationInfoSelector);
    const activeAnnotation = useAnnotationContextSelector(activeAnnotationSelector);
    const annotationColor = useAnnotationContextSelector(annotationColorSelector);
    const annotationSize = useAnnotationContextSelector(annotationSizeSelector);
    const isAnnotationsEnabled = useAnnotationContextSelector(isAnnotationsEnabledSelector);
    const activeTimestamp = useSelector(activeTimestampParamSelector);

    const { setNewAnnotation, clearNewAnnotation, clearRevertedLines } = useAnnotationContext();

    const onAnnotationDrawn = useCallback(
      (annotation: FreeformAnnotation) => {
        setNewAnnotation(annotation);
      },
      [setNewAnnotation],
    );

    const { height: containerHeight, width: containerWidth } = useResizeDetector<HTMLDivElement>({
      handleHeight: true,
      refreshMode: 'throttle',
      refreshRate: 300,
      targetRef: containerRef,
    });

    useMouseTooltip({
      // This complains about Ref types but it works as expected
      // @ts-ignore
      containerRef: containerRef,
      tooltipRef: annotationTooltipRef,
      disabled: !canDrawAnnotation || !!newAnnotation,
      dependencies: [pageNumber],
    });

    const isDisabled = !canDrawAnnotation || (!isAnnotationsEnabled && !activeAnnotation);

    const handleIsSelectingChange = useCallback(
      (isSelecting: boolean) => {
        Router.ready(() => {
          clearRevertedLines();
          onIsSelectingChange?.(isSelecting);
        });
      },
      [clearRevertedLines, onIsSelectingChange],
    );

    const clearActiveAnnotation = useCallback(() => {
      setQueryParams({ [QueryParamNames.discussionId]: null }, 'replace');
    }, []);

    const annotationLayer = useMemo(() => {
      const props = {
        pageNumber,
        disabled: isDisabled,
        containerWidth: containerWidth ?? 0,
        containerHeight: containerHeight ?? 0,
        onIsSelectingChange: handleIsSelectingChange,
        containerRef,
        onAnnotationDrawn,
        onClear: clearNewAnnotation,
        containerLeft: containerRect?.left ?? 0,
        containerTop: containerRect?.top ?? 0,
      };

      const newAnnotationToDraw = getPageNumber(newAnnotation) === pageNumber ? newAnnotation : undefined;

      switch (annotationType) {
        case 'box': {
          // we want to draw overlay always when there is any annotation or newAnnotation
          // e.g. on PDFs, we want to draw overlay on each page if user draw or clicked annotation on any page
          const hasSomethingToDraw = !!activeAnnotation || !!newAnnotation;
          const hasOnlyBoxes =
            !(activeAnnotation?.type === 'box' && isPointAnnotation(activeAnnotation)) &&
            (!newAnnotation || (newAnnotation?.type === 'box' && !isPointAnnotation(newAnnotation)));

          const shouldDrawOverlay = hasSomethingToDraw && hasOnlyBoxes;

          return (
            <BoxAnnotationLayer
              {...props}
              shouldDrawOverlay={shouldDrawOverlay}
              newAnnotation={
                !newAnnotationToDraw?.type || newAnnotationToDraw?.type === 'box' ? newAnnotationToDraw : undefined
              }
              activeAnnotation={activeAnnotation?.type === 'box' ? activeAnnotation : undefined}
            />
          );
        }

        case 'pencil': {
          const pencilAnnotation =
            activeAnnotation?.type === 'pencil' && getPageNumber(activeAnnotation) === pageNumber
              ? activeAnnotation
              : undefined;

          return (
            <PencilAnnotationLayer
              {...props}
              onNewLineDrawStart={clearActiveAnnotation}
              lineWidth={pencilAnnotation?.lines?.[0]?.lineWidth ?? annotationSize}
              lineColor={pencilAnnotation?.lines?.[0]?.color ?? annotationColor}
              newAnnotation={newAnnotationToDraw?.type === 'pencil' ? newAnnotationToDraw : undefined}
              activeAnnotation={pencilAnnotation}
            />
          );
        }
      }
    }, [
      activeAnnotation,
      annotationColor,
      annotationSize,
      annotationType,
      clearActiveAnnotation,
      clearNewAnnotation,
      containerHeight,
      containerRect?.left,
      containerRect?.top,
      containerWidth,
      handleIsSelectingChange,
      isDisabled,
      newAnnotation,
      onAnnotationDrawn,
      pageNumber,
    ]);

    const onAvatarClick = useCallback((discussion: ClipDiscussion) => {
      setQueryParams({ [QueryParamNames.discussionId]: [discussion.id] }, 'replace');
    }, []);

    if (isFullscreen) {
      return null;
    }

    return (
      <>
        <div
          data-testid={ANNOTATION_LAYER}
          ref={containerRef}
          className={classNames(
            'absolute left-1/2 max-w-full -translate-x-1/2 -translate-y-1/2 transform',
            isDisabled
              ? '[&>canvas]:pointer-events-none'
              : annotationType === 'box'
              ? '[&>canvas]:cursor-box-annotation'
              : '[&>canvas]:cursor-pencil-annotation',
          )}
          style={{
            zIndex: BOUNDING_BOX_LAYER_Z_INDEX,
            height,
            width,
            minWidth: width,
            minHeight: height,
            top: `calc(50% + ${topOffset}px)`,
            pointerEvents: isDisabled ? 'none' : 'auto',
          }}
        >
          {!!containerWidth && !!containerHeight && !hideAnnotations && (
            <div className="pointer-events-none absolute inset-0">
              <AnnotationsAvatarsContainer
                containerWidth={containerWidth}
                containerHeight={containerHeight}
                containerLeft={containerRect?.left ?? 0}
                containerTop={containerRect?.top ?? 0}
                timestamp={activeTimestamp}
                pageNumber={pageNumber}
                onAvatarClick={onAvatarClick}
                hideAnnotations={!!newAnnotation || hideAnnotations}
              />
            </div>
          )}
          {isAnnotationsEnabled || !!activeAnnotation ? annotationLayer : null}
          {isAnnotationsEnabled && annotationType && !activeAnnotation && (
            <AnnotationTooltip
              text={annotationTooltipText[annotationType]}
              innerRef={annotationTooltipRef}
              shouldDisplay={canDrawAnnotation}
            />
          )}
        </div>
        <AnnotationsKeyShortcuts enabled={keyShortcutsEnabled} />
        {containerWidth === width && containerHeight === height && isCypressRun() && (
          <div data-testid="CYPRESS_ANNOTATIONS_READY" />
        )}
      </>
    );
  },
);

Annotations.displayName = 'Annotations';
