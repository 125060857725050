import { FullScreenEnter as FullScreenEnterIcon, FullScreenOut as FullScreenOutIcon } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { memo } from 'react';

interface FullScreenButtonProps {
  onFullScreenToggle: () => void;
  isFullScreen: boolean;
  iconText: string;
}

export const FullScreenButton = memo(({ onFullScreenToggle, isFullScreen, iconText }: FullScreenButtonProps) => {
  return (
    <IconButton
      appearance="ghost"
      color="grey"
      onClick={onFullScreenToggle}
      className="ml-0 md:ml-2"
      label={iconText}
      size="large"
    >
      {isFullScreen ? <FullScreenOutIcon className="size-6" /> : <FullScreenEnterIcon className="size-6" />}
    </IconButton>
  );
});

FullScreenButton.displayName = 'FullScreenButton';
