import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FileExtensionName, fileExtensionStylingMap, genericFile } from '~/components/FileExtensionPreview/types';
import { CLIP_PREVIEW_IMAGE } from '~/constants/testIDs';
import {
  centralizedClipExtSelector,
  centralizedClipFormattedSizeSelector,
  centralizedDisplayTitleSelector,
} from '~/store/centralizedClip/selectors';

export const FileVisualizer = memo(() => {
  const ext = useSelector(centralizedClipExtSelector);
  const title = useSelector(centralizedDisplayTitleSelector);
  const formattedSize = useSelector(centralizedClipFormattedSizeSelector);

  const extStyles = useMemo(() => {
    const normalizedExt = ext?.toLowerCase() || ''; // to support e.g. JPG
    return normalizedExt in fileExtensionStylingMap
      ? fileExtensionStylingMap[normalizedExt as FileExtensionName]
      : genericFile;
  }, [ext]);

  return (
    <div
      data-testid={CLIP_PREVIEW_IMAGE}
      className="flex min-h-[380px] flex-col items-center justify-center md:min-h-0"
    >
      {/* ICON */}
      <div
        className="flex size-[64px] items-center justify-center rounded-lg md:size-[100px] md:rounded-xl"
        style={{
          backgroundColor: extStyles.bgColor,
          color: extStyles.iconColor,
        }}
      >
        <extStyles.iconComponent className="block w-10 md:w-16" />
      </div>
      <div className="mt-4 text-18 font-medium text-grey-12 md:mt-6 md:text-24">{title}</div>
      <div className="mt-1 text-14 text-grey-10 md:mt-2 md:text-16">{`${ext} ∙ ${formattedSize}`}</div>
    </div>
  );
});

FileVisualizer.displayName = 'FileVisualizer';
