import { Filters } from '@air/next-icons';
import { memo, PropsWithChildren } from 'react';

type CommentsFilterBarProps = PropsWithChildren<object>;

export const CommentsFilterBar = memo(({ children }: CommentsFilterBarProps) => {
  return (
    <div className="-mt-2.5 h-8">
      <div className="flex items-center">
        <Filters className="mr-2 size-3 shrink-0 text-grey-10" />
        {children}
      </div>
    </div>
  );
});

CommentsFilterBar.displayName = 'CommentsFilterBar';
