import { memo, useMemo } from 'react';

import { type FileExtensionName, fileExtensionStylingMap, genericFile } from '~/components/FileExtensionPreview/types';

export type AssetMobileVersionsListItemThumbnailPreviewProps = {
  ext?: string;
};

export const AssetMobileVersionsListItemThumbnailPreview = memo(
  ({ ext }: AssetMobileVersionsListItemThumbnailPreviewProps) => {
    const extStyles = useMemo(() => {
      const normalizedExt = ext?.toLowerCase() || ''; // to support e.g. JPG

      return normalizedExt in fileExtensionStylingMap
        ? fileExtensionStylingMap[normalizedExt as FileExtensionName]
        : genericFile;
    }, [ext]);

    const IconComponent = extStyles.iconComponent;

    return (
      <div
        className="flex size-full items-center justify-center text-grey-12"
        style={{
          color: extStyles.iconColor,
          backgroundColor: extStyles.bgColor,
        }}
      >
        <IconComponent className="size-6 text-current" />
      </div>
    );
  },
);

AssetMobileVersionsListItemThumbnailPreview.displayName = 'AssetMobileVersionsListItemThumbnailPreview';
