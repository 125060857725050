import { Fields } from '@air/next-icons';
import { memo } from 'react';

export const EmptyAssetCustomFields = memo(() => (
  <div className="flex h-full flex-col items-center justify-center gap-4 p-4">
    <Fields className="size-[72px] text-grey-7" />
    <p className="text-20 font-medium text-grey-8">No custom fields</p>
    <p className="text-center text-14 text-grey-8">Start applying fields to assets by creating custom fields</p>
  </div>
));

EmptyAssetCustomFields.displayName = 'EmptyAssetCustomFields';
