import { PublicClip, ScopedPermissions } from '@air/api';
import { Clip } from '@air/api/types';
import { Eye } from '@air/next-icons';
import { formatDateVerbose } from '@air/utilities';
import classNames from 'classnames';
import { isUndefined } from 'lodash';
import { memo, MouseEvent, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { QueryParamNames } from '~/constants/search';
import { VERSION_SNIPPET } from '~/constants/testIDs';
import {
  centralizedClipIdSelector,
  centralizedClipOpenDiscussionCountSelector,
} from '~/store/centralizedClip/selectors';
import { useSwitchVersion } from '~/swr-hooks/versions/useSwitchVersion';
import { clearThumbnailCache } from '~/utils/ClipUtils';

import { VersionListItemThumbnail } from './VersionListItemThumbnail/VersionListItemThumbnail';

export interface VersionListItemProps {
  version: Clip | PublicClip;
  permissions: ScopedPermissions | undefined;
  menu?: ReactNode;
}

export const VersionListItem = memo(({ version, menu, permissions }: VersionListItemProps) => {
  const clipId = useSelector(centralizedClipIdSelector);
  const openDiscussionCount = useSelector(centralizedClipOpenDiscussionCountSelector);
  const { switchVersion } = useSwitchVersion();

  const isActive = clipId === version.id;

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    switchVersion({
      version: { ...version, openDiscussionCount },
      location: 'tab',
      query: {
        [QueryParamNames.timestamp]: null,
        [QueryParamNames.discussionId]: null,
      },
    });
  };

  /**
   * We don't want to render the versions unless their permissions have been fetched
   * because if a user clicks on one too fast (more likely Cypress), the UI of the asset modal
   * will bounce all over the place because the permissons wont' be defined
   */
  if (!permissions) {
    return null;
  }

  return (
    <div
      className={classNames(
        'group/version-list-item version-list-item transition-background relative box-border flex h-full max-h-[116px] w-[120px] cursor-pointer flex-col items-start justify-start rounded bg-transparent p-1 duration-[125ms] ease-in-out hover:bg-grey-5',
        isActive
          ? '[&>.asset-modal-version-thumbnail]:shadow-[0px_0px_0px_2px] [&>.asset-modal-version-thumbnail]:shadow-blue-9'
          : '',
      )}
      onClick={onClick}
      data-testid={`${VERSION_SNIPPET}-${version.version}`}
    >
      <div className="opacity-0 group-hover/version-list-item:opacity-100">{menu}</div>
      <VersionListItemThumbnail
        isCurrentVersion={version.isDefault}
        src={clearThumbnailCache({
          clipExt: version.ext,
          clipStatus: version.status,
          imageSrc: version.assets.image,
          clipUpdatedAt: version.updatedAt,
        })}
        status={version.status}
        ext={version.ext}
        type={version.type}
      />
      <div className="absolute right-3 top-[52px] flex items-center justify-center rounded-sm bg-black/80 px-1 py-0.5">
        {isActive && <Eye className="mr-0.5 size-3 text-white" />}
        {!isUndefined(version.version) && (
          <div className="text-10 font-bold uppercase text-grey-12">{`V${version.version}`}</div>
        )}
      </div>
      <div className="mt-1.5 w-full truncate text-12 font-medium text-grey-12">
        {version.title || version.importedName || ''}
      </div>
      <div className="flex w-full gap-1 truncate text-12 text-grey-10">
        <span className="uppercase">{version.ext}</span>·<span>{formatDateVerbose(version.createdAt)}</span>
      </div>
    </div>
  );
});

VersionListItem.displayName = 'VersionListItem';
