import {
  ChevronLeft,
  ChevronRight,
  DoubleChevronLeft,
  DoubleChevronRight,
  Play,
  Redo,
  VolumeHigh,
  VolumeLow,
  VolumeMuted,
} from '@air/next-icons';
import { memo } from 'react';

import { type Shortcut, ShortcutsList } from '~/components/ShortcutsList';
const VIDEO_SHORTCUTS: Shortcut[] = [
  {
    id: 'play-pause',
    icon: Play,
    label: 'Play/Pause video',
    keys: ['space', 'k'],
  },
  {
    id: 'forward',
    icon: DoubleChevronRight,
    label: 'Fast forward 1x, 2x, 4x, 8x',
    keys: ['l'],
  },
  {
    id: 'rewind',
    icon: DoubleChevronLeft,
    label: 'Rewind 1x, 2x, 4x, 8x',
    keys: ['j'],
  },
  {
    id: 'jump-forward-1s',
    icon: ChevronRight,
    label: 'Jump 10s forward',
    keys: ['shift+right'],
  },
  {
    id: 'jump-backward-1s',
    icon: ChevronLeft,
    label: 'Jump 10s backward',
    keys: ['shift+left'],
  },
  {
    id: 'mute',
    icon: VolumeMuted,
    label: 'Mute',
    keys: ['m'],
  },
  {
    id: 'increase-volume',
    icon: VolumeHigh,
    label: 'Increase volume',
    keys: ['up'],
  },
  {
    id: 'decrease-volume',
    icon: VolumeLow,
    label: 'Decrease volume',
    keys: ['down'],
  },
  {
    id: 'jump-to',
    icon: Redo,
    label: 'Jump to… (10% increments)',
    keys: ['1', '9'],
    separator: '…',
  },
];

export const VideoShortcutsInfo = memo(() => {
  return <ShortcutsList shortcuts={VIDEO_SHORTCUTS} />;
});

VideoShortcutsInfo.displayName = 'BasicShortcutsInfo';
