import { Discussions } from '@air/next-icons';
import { memo } from 'react';

export const EmptyComments = memo(() => (
  <div className="flex h-full flex-col items-center justify-center gap-4 p-4">
    <Discussions className="size-[72px] text-grey-7" />
    <p className="text-20 font-medium text-grey-8">No comments</p>
    <p className="text-center text-14 text-grey-8">
      Start reviewing assets and giving feedback with comments and annotations
    </p>
  </div>
));

EmptyComments.displayName = 'EmptyComments';
