import { ArrowRight } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { memo, MouseEventHandler, useCallback } from 'react';

import { ClipDiscussion } from '~/components/Annotations/shared/types';
import { useDiscussionPanelContext } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/DiscussionsPanelContext';
import { ANNOTATION_REPLY_BUTTON } from '~/constants/testIDs';
import { AssetModalPanel, useAssetModalPanelContext } from '~/providers/AssetModalPanelContextProvider';

export interface AnnotationReplyButtonProps {
  discussion: ClipDiscussion;
}

export const AnnotationReplyButton = memo(({ discussion }: AnnotationReplyButtonProps) => {
  const { setReplyingDiscussionId } = useDiscussionPanelContext();
  const { showPanel } = useAssetModalPanelContext();

  const onReplyClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.stopPropagation();
      showPanel(AssetModalPanel.COMMENTS);
      setReplyingDiscussionId(discussion.id);
    },
    [discussion.id, setReplyingDiscussionId, showPanel],
  );

  return (
    <Button
      appearance="ghost"
      color="grey"
      className="-ml-2"
      data-testid={ANNOTATION_REPLY_BUTTON}
      onClick={onReplyClick}
      suffix={<ArrowRight className="size-4" />}
      size="small"
    >
      Reply
    </Button>
  );
});

AnnotationReplyButton.displayName = 'AnnotationReplyButton';
