import { Board } from '@air/api/types';
import { ReactNode } from 'react';

import { BoardAncestorPath } from '~/components/BoardAncestorPath';

export interface DiscussionsMenuItem extends Pick<Board, 'ancestors' | 'title'> {
  adornmentRight?: ReactNode;
  thumbnail?: ReactNode;
}

export const DiscussionsMenuItem = ({ adornmentRight, ancestors, thumbnail, title }: DiscussionsMenuItem) => {
  return (
    <div className="my-1.5 flex w-full items-center">
      <div className="mr-3 size-10 overflow-hidden rounded">{thumbnail}</div>

      <div className="flex h-full flex-1 flex-col items-start justify-center">
        <div
          className="w-full truncate text-left text-14 font-normal text-inherit"
          style={{
            marginBottom: ancestors && !!ancestors.length ? 2 : 0,
          }}
        >
          {title}
        </div>

        {!!ancestors && !!ancestors.length && <BoardAncestorPath ancestors={ancestors} />}
      </div>

      {adornmentRight}
    </div>
  );
};
