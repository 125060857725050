import { AssetCustomFieldValue, BoardCustomFieldValue } from '@air/api/types';
import { Pencil, Trash } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { memo, useMemo } from 'react';

import { customFieldIconMap } from '~/constants/customFieldIconMap';
import { CUSTOM_FIELD_EDIT_BUTTON, CUSTOM_FIELD_SELECT_LABEL } from '~/constants/testIDs';

export interface CustomFieldLabelProps {
  onClick?: () => void;
  canDeleteCustomFields: boolean;
  canEditCustomFields: boolean;
  field: BoardCustomFieldValue | AssetCustomFieldValue;
}

export const CustomFieldLabel = memo(
  ({ onClick, field, canDeleteCustomFields, canEditCustomFields }: CustomFieldLabelProps) => {
    const Icon = customFieldIconMap[field.type.title];
    const label = field.name;
    const iconAndLabel = useMemo(
      () => (
        <div className="flex items-center gap-1 text-grey-10">
          <Icon className="size-3" />
          {label}
        </div>
      ),
      [Icon, label],
    );

    return canDeleteCustomFields || canEditCustomFields ? (
      <Button
        appearance="ghost"
        className="group/custom-field-label -ml-2"
        color="grey"
        data-testid={`${CUSTOM_FIELD_EDIT_BUTTON}-${label}`}
        onClick={onClick}
        suffix={
          canEditCustomFields || canDeleteCustomFields ? (
            <div className="text-grey-10 opacity-0 group-focus-within/custom-field-label:opacity-100 group-hover/custom-field-label:opacity-100">
              {canEditCustomFields ? (
                <Pencil className="size-3" />
              ) : canDeleteCustomFields ? (
                <Trash className="size-3" />
              ) : undefined}
            </div>
          ) : undefined
        }
        size="small"
      >
        {iconAndLabel}
      </Button>
    ) : (
      <div className="flex h-6 items-center text-12" data-testid={`${CUSTOM_FIELD_SELECT_LABEL}-${label}`}>
        {iconAndLabel}
      </div>
    );
  },
);

CustomFieldLabel.displayName = 'CustomFieldLabel';
