import { Comment } from '@air/next-icons';

export type CommentCountProps = {
  count?: number;
};

export const CommentCount = ({ count = 0 }: CommentCountProps) => {
  return (
    <div className="mr-2 flex items-center text-12 text-grey-11">
      <Comment className="mr-1 flex size-4" />
      {count}
    </div>
  );
};
