import * as RadixProgress from '@radix-ui/react-progress';

export type BufferProgressBarProps = {
  percentage: number;
};

export const BufferProgressBar = ({ percentage }: BufferProgressBarProps) => {
  return (
    <RadixProgress.Root
      className="pointer-events-none absolute top-1.5 h-1 w-full overflow-hidden rounded"
      value={percentage}
    >
      <RadixProgress.Indicator
        className="size-full rounded bg-pigeon-300"
        style={{ transform: `translateX(-${100 - percentage}%)` }}
      />
    </RadixProgress.Root>
  );
};
