import { DiscussionLocation, useTrackResolvedDiscussion } from '@air/analytics';
import { Annotation, Clip } from '@air/api/types';
import { Discussion as DiscussionType } from '@air/types';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getAnnotationAnalyticsType, isAnnotationCorrect } from '~/components/Annotations/shared/annotationUtils';
import { useUpdateStateAfterAddingOrRemovingDiscussion } from '~/components/Discussions/utils';
import { QueryParamNames } from '~/constants/search';
import {
  centralizedClipAssetIdSelector,
  centralizedClipSelector,
  centralizedClipTypeSelector,
} from '~/store/centralizedClip/selectors';
import { useResolveDiscussion } from '~/swr-hooks/discussions/useResolveDiscussion';
import { getBoardIdFromPath, setQueryParams } from '~/utils/PathUtils';
import { useAirStore } from '~/utils/ReduxUtils';

export const useResolveCommentClick = ({ trackLocation }: { trackLocation: DiscussionLocation }) => {
  const store = useAirStore();
  const assetId = useSelector(centralizedClipAssetIdSelector);
  const { resolveDiscussion } = useResolveDiscussion();
  const { updateStateAfterAddingOrRemovingDiscussion } = useUpdateStateAfterAddingOrRemovingDiscussion();
  const { trackResolvedDiscussion } = useTrackResolvedDiscussion();

  const handleResolveCommentClick = useCallback(
    ({
      id,
      resolved,
      clipId,
      timestamp,
      annotation,
    }: {
      id: DiscussionType['id'];
      resolved: DiscussionType['resolved'];
      clipId: Clip['id'];
      timestamp?: number;
      annotation?: Annotation;
    }) => {
      const boardId = getBoardIdFromPath(window.location.pathname);
      const { openCommentCount, openDiscussionCount } = centralizedClipSelector(store.getState());

      trackResolvedDiscussion({
        boardId,
        clipId,
        location: trackLocation,
        has_timestamp: !isUndefined(timestamp),
        has_annotation: isAnnotationCorrect(annotation),
        annotationType: getAnnotationAnalyticsType(annotation),
        assetType: centralizedClipTypeSelector(store.getState()),
      });

      const decrementedCommentCount = !isUndefined(openCommentCount) ? openCommentCount - 1 : 0;
      const decrementedDiscussionCount = !isUndefined(openDiscussionCount) ? openDiscussionCount - 1 : 0;

      const incrementedCommentCount = !isUndefined(openCommentCount) ? openCommentCount + 1 : 0;
      const incrementedDiscussionCount = !isUndefined(openDiscussionCount) ? openDiscussionCount + 1 : 0;

      resolveDiscussion({ id, resolved, assetId, boardId });

      updateStateAfterAddingOrRemovingDiscussion({
        newCommentCount: resolved ? decrementedCommentCount : incrementedCommentCount,
        newDiscussionCount: resolved ? decrementedDiscussionCount : incrementedDiscussionCount,
        boardId,
        clipId,
      });

      setQueryParams({ [QueryParamNames.discussionId]: null }, 'replace');
    },
    [
      assetId,
      resolveDiscussion,
      store,
      trackLocation,
      updateStateAfterAddingOrRemovingDiscussion,
      trackResolvedDiscussion,
    ],
  );

  return {
    handleResolveCommentClick,
  };
};
