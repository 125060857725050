import { PencilAnnotation } from '@air/api/types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import simplify from 'simplify-js';

import { drawStartingPoint } from '~/components/Annotations/components/canvasUtils';
import { AnnotationCommentInput } from '~/components/Annotations/shared/AnnotationCommentInput';
import { getAnnotationCommentPos, getPageNumber } from '~/components/Annotations/shared/annotationUtils';
import { BaseAnnotationLayerProps } from '~/components/Annotations/shared/types';
import { DrawingCanvas, DrawingCanvasHandle } from '~/components/BoundingBox/components/DrawingCanvas';
import { Point } from '~/components/BoundingBox/types';

interface PenAnnotationLayerProps extends BaseAnnotationLayerProps<PencilAnnotation> {
  lineWidth: number;
  lineColor: string;
  onNewLineDrawStart?: (event: React.MouseEvent) => void;
}

export const PencilAnnotationLayer = memo(
  ({
    containerRef,
    disabled,
    containerWidth,
    containerHeight,
    containerLeft,
    containerTop,
    onIsSelectingChange,
    onAnnotationDrawn,
    pageNumber,
    newAnnotation,
    lineColor,
    lineWidth,
    activeAnnotation,
    onNewLineDrawStart,
  }: PenAnnotationLayerProps) => {
    const annotationLayerRef = useRef<DrawingCanvasHandle>(null);

    const [isDrawing, setIsDrawing] = useState(false);
    const paths = useRef<Point[][]>([]);

    const annotationToDraw = newAnnotation || activeAnnotation;

    const addPointToPath = useCallback((point: Point) => {
      const lastIndex = paths.current.length;
      paths.current[lastIndex - 1].push(point);
    }, []);

    // This will draw a rectangle on the canvas based on user input.
    const onMouseMove = useCallback(
      (event: MouseEvent) => {
        const ctx = annotationLayerRef.current?.getCanvas()?.getContext('2d');
        const mouseCoords = annotationLayerRef.current?.getMouseCoords({ event });

        if (ctx && mouseCoords) {
          ctx.strokeStyle = lineColor;
          ctx.lineWidth = lineWidth;

          ctx.lineTo(mouseCoords.x, mouseCoords.y);
          ctx.stroke();
          addPointToPath(mouseCoords);
        }
      },
      [addPointToPath, lineColor, lineWidth],
    );

    // This will set the drawing in context, as well as generate and set percentage
    // values for each corner of the rect.
    const onMouseUp = useCallback(() => {
      setIsDrawing(false);

      const lastIndex = paths.current.length;
      paths.current[lastIndex - 1] = simplify(paths.current[lastIndex - 1], 1, false);

      if (containerWidth && containerHeight) {
        paths.current[lastIndex - 1] = simplify(paths.current[lastIndex - 1], 1, false).map(({ x, y }) => ({
          x: x / containerWidth,
          y: y / containerHeight,
        }));

        onAnnotationDrawn({
          type: 'pencil',
          lines: paths.current.map((path) => ({
            coordinates: path,
            pageNumber,
            color: lineColor,
            lineWidth: lineWidth,
          })),
        });
      }

      onIsSelectingChange?.(false);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    }, [
      containerHeight,
      containerWidth,
      lineColor,
      lineWidth,
      onAnnotationDrawn,
      onIsSelectingChange,
      onMouseMove,
      pageNumber,
    ]);

    const redrawPaths = useCallback(() => {
      const ctx = annotationLayerRef.current?.getCanvas()?.getContext('2d');
      if (ctx && annotationToDraw) {
        annotationLayerRef.current?.clearCanvas();
        ctx.strokeStyle = lineColor;
        ctx.lineWidth = lineWidth;

        annotationToDraw.lines.forEach((path) => {
          ctx.beginPath();

          path.coordinates.forEach(({ x, y }, index) => {
            const containerX = x * containerWidth;
            const containerY = y * containerHeight;
            if (index === 0) {
              drawStartingPoint({ point: { x: containerX, y: containerY }, ctx, lineSize: lineWidth / 2, lineColor });
            } else {
              ctx.lineTo(containerX, containerY);
              ctx.stroke();
            }
          });
        });
      }
    }, [containerHeight, containerWidth, lineColor, lineWidth, annotationToDraw]);

    const onMouseDown = useCallback(
      (event: React.MouseEvent) => {
        event.stopPropagation();

        if (activeAnnotation) {
          onNewLineDrawStart?.(event);
          return;
        } else {
          paths.current.push([]);
        }

        const ctx = annotationLayerRef.current?.getCanvas()?.getContext('2d');

        const mouseCoords = annotationLayerRef.current?.getMouseCoords({ event });

        if (ctx && mouseCoords) {
          setIsDrawing(true);

          drawStartingPoint({ point: mouseCoords, ctx, lineSize: lineWidth / 2, lineColor });
          addPointToPath(mouseCoords);

          onIsSelectingChange?.(true);
          window.addEventListener('mousemove', onMouseMove);
          window.addEventListener('mouseup', onMouseUp);
        }
      },
      [
        activeAnnotation,
        addPointToPath,
        lineColor,
        lineWidth,
        onIsSelectingChange,
        onMouseMove,
        onMouseUp,
        onNewLineDrawStart,
      ],
    );

    useEffect(() => {
      redrawPaths();
      // omit redrawPaths - we want to call it when color or lineWidth changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineColor, lineWidth]);

    useEffect(() => {
      if (!annotationToDraw) {
        paths.current = [];
        annotationLayerRef.current?.clearCanvas();
      } else {
        const annotationLines = annotationToDraw.lines.map((line) => line.coordinates);
        if (!isEqual(paths.current, annotationLines)) {
          paths.current = annotationToDraw.lines.map((line) => line.coordinates);
          annotationLayerRef.current?.clearCanvas();
          redrawPaths();
        }
      }
    }, [annotationToDraw, redrawPaths]);

    const { maxY, maxX, minX } = useMemo(
      () => (annotationToDraw ? getAnnotationCommentPos(annotationToDraw) : { maxX: 0, maxY: 0, minX: 0 }),
      [annotationToDraw],
    );

    return (
      <>
        <DrawingCanvas
          hasCustomCursor
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          onMouseDown={disabled ? undefined : onMouseDown}
          ref={annotationLayerRef}
          onSizeChanged={redrawPaths}
          containerLeft={containerLeft}
          containerTop={containerTop}
        />
        {!isDrawing &&
          newAnnotation &&
          getPageNumber(newAnnotation) === pageNumber &&
          newAnnotation.lines.length > 0 && (
            <AnnotationCommentInput
              containerRef={containerRef}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              startX={minX}
              endX={maxX}
              endY={maxY}
              containerLeft={containerLeft}
            />
          )}
      </>
    );
  },
);

PencilAnnotationLayer.displayName = 'PenAnnotationLayer';
