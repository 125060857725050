import { useTrackCommentModeToggledOff, useTrackCommentModeToggledOn } from '@air/analytics';
import { useBreakpointsContext } from '@air/provider-media-query';
import { formatDuration } from '@air/utilities';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'lodash';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { TimestampButton } from '~/components/Discussions/DiscussionForm/TimestampButton';
import { AnnotateActionButton } from '~/components/Discussions/InitialCommentInput/components/AnnotateActionButton';
import { useAnnotationContext, useAnnotationContextSelector } from '~/providers/AnnotationProvider/AnnotationProvider';
import { isAnnotationsEnabledSelector } from '~/providers/AnnotationProvider/annotationProviderUtils';
import { useVideoControlContext } from '~/providers/VideoControlsProvider';
import { useVideoTimestampContext } from '~/providers/VideoTimestampProvider';
import { centralizedClipTypeSelector } from '~/store/centralizedClip/selectors';
import { activeTimestampParamSelector } from '~/store/router/selectors';
import { isMobileAgent } from '~/utils/PlatformHelpers';

export interface NewDiscussionActionsProps {
  shouldEnableTimestamps: boolean;
  setShouldEnableTimestamps: (enable: boolean) => void;
  shouldDisableTimestampsAndAnnotations: boolean;
  canAnnotateClip: boolean;
}

export const NewDiscussionActions = memo(
  ({
    setShouldEnableTimestamps,
    shouldEnableTimestamps,
    shouldDisableTimestampsAndAnnotations,
    canAnnotateClip,
  }: NewDiscussionActionsProps) => {
    const { videoTimestamp: hasVideoTimestampFlag } = useFlags();
    const { setAnnotationsEnabled } = useAnnotationContext();

    const activeTimestamp = useSelector(activeTimestampParamSelector);
    const { isAboveMediumScreen } = useBreakpointsContext();
    const isAnnotationsEnabled = useAnnotationContextSelector(isAnnotationsEnabledSelector);
    const { trackCommentModeToggledOff } = useTrackCommentModeToggledOff();
    const { trackCommentModeToggledOn } = useTrackCommentModeToggledOn();

    const clipType = useSelector(centralizedClipTypeSelector);
    const { pause } = useVideoControlContext();
    const { currentTime, handleClearTimestamp, handleSetTimestamp } = useVideoTimestampContext();

    const isDesktop = isAboveMediumScreen && !isMobileAgent;

    const onEnableTimestampsClick = useCallback(
      (enable: boolean) => {
        setShouldEnableTimestamps(enable);

        if (enable) {
          if (hasVideoTimestampFlag) {
            handleSetTimestamp();
          } else {
            pause();
          }
        } else {
          if (hasVideoTimestampFlag) {
            handleClearTimestamp();
          }
        }
      },
      [handleClearTimestamp, handleSetTimestamp, hasVideoTimestampFlag, pause, setShouldEnableTimestamps],
    );

    const handleAnnotateClick = useCallback(() => {
      if (isAnnotationsEnabled) {
        trackCommentModeToggledOff();
      } else {
        trackCommentModeToggledOn();
      }
      setAnnotationsEnabled(!isAnnotationsEnabled);
    }, [isAnnotationsEnabled, setAnnotationsEnabled, trackCommentModeToggledOff, trackCommentModeToggledOn]);

    return isDesktop ? (
      <div className="flex items-center gap-2 pl-2">
        {canAnnotateClip && (
          <AnnotateActionButton
            isActive={isAnnotationsEnabled}
            onClick={handleAnnotateClick}
            shouldDisableTimestampsAndAnnotations={shouldDisableTimestampsAndAnnotations}
          />
        )}
        {clipType === 'video' && (
          <TimestampButton
            shouldEnableTimestamps={
              shouldEnableTimestamps && !isNil(hasVideoTimestampFlag ? currentTime : activeTimestamp)
            }
            setShouldEnableTimestamps={onEnableTimestampsClick}
            formattedTimestamp={formatDuration(
              Math.round((hasVideoTimestampFlag ? currentTime : activeTimestamp) ?? 0),
            )}
            shouldDisableTimestampsAndAnnotations={shouldDisableTimestampsAndAnnotations}
          />
        )}
      </div>
    ) : null;
  },
);

NewDiscussionActions.displayName = 'NewDiscussionActions';
