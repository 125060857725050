import { Discussions } from '@air/next-icons';
import { Button, ButtonProps } from '@air/primitive-button';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { centralizedClipSelector } from '~/store/centralizedClip/selectors';

export type DiscussionsMenuButtonProps = ButtonProps;

export const DiscussionsMenuButton = forwardRef<HTMLButtonElement, DiscussionsMenuButtonProps>(
  (props: DiscussionsMenuButtonProps, ref) => {
    const asset = useSelector(centralizedClipSelector);

    return (
      <Button appearance="ghost" className="px-1" color="grey" ref={ref} size="small" {...props}>
        <Discussions className="size-4" />
        <span className="ml-1">{asset.openCommentCount}</span>
      </Button>
    );
  },
);

DiscussionsMenuButton.displayName = 'DiscussionsMenuButton';
