import { DoubleChevronLeft, DoubleChevronRight } from '@air/next-icons';

export type VideoPlaybackSpeedAlertProps = {
  playbackSpeed: number;
};

export const VideoPlaybackSpeedAlert = ({ playbackSpeed }: VideoPlaybackSpeedAlertProps) => {
  return (
    <div className="pointer-events-none absolute left-1/2 top-1/2 z-30 flex size-20 -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center rounded-full bg-grey-1 text-white shadow-popover">
      {playbackSpeed < 0 ? <DoubleChevronLeft className="size-8" /> : <DoubleChevronRight className="size-8" />}
      <div className="text-12 font-semibold">{Math.abs(playbackSpeed)}x</div>
    </div>
  );
};
