import { Members } from '@air/api';
import { BoardAncestors, WorkspaceMemberType } from '@air/api/types';
import { formatFullName } from '@air/utilities';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';

import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { ONE_HOUR } from '~/constants/unitsOfTime';
import { useAccountContext } from '~/providers/AccountProvider';
import { isActiveMember } from '~/swr-hooks/members/utils/memberUtils';

export const MEMBERS_FOR_MENTIONS = 'MEMBERS_FOR_MENTIONS';

export const getMembersForMentionsKey = (workspaceId?: string) => {
  return [MEMBERS_FOR_MENTIONS, { workspaceId }];
};

export const useGetDiscussionMentionsMembers = ({
  boardId,
  ancestors,
  workspaceId,
}: {
  boardId?: string;
  ancestors: BoardAncestors;
  workspaceId: string;
}) => {
  const { data: account } = useAccountContext();

  const isWorkspaceMember = account?.workspaces.some((w) => w.id === workspaceId);

  const { data: members = [] } = useQuery({
    queryKey: getMembersForMentionsKey(workspaceId),

    queryFn: async () => {
      const { members } = await Members.list({
        workspaceId,
        options: {
          memberTypes: [WorkspaceMemberType.member, WorkspaceMemberType.guest],
        },
      });
      return members;
    },
    enabled: !!account && !!workspaceId && isWorkspaceMember,
    staleTime: ONE_HOUR,
  });

  const activeMembers = members.filter((member) => isActiveMember(member) && member.type !== 'guest');
  const activeGuests = members.filter((member) => isActiveMember(member) && member.type === 'guest');

  const mentionMembers = useMemo<MentionsData[]>(() => {
    const ancestorsIds = ancestors.map((ancestor) => ancestor.id);
    return uniqBy(
      [
        ...activeMembers,
        ...activeGuests.filter(({ roles }) =>
          roles.guest.find((b) => b.boardId === boardId || ancestorsIds.includes(b.boardId)),
        ),
      ],
      'accountId',
    ).map(({ firstName, lastName, avatar, accountId, roles, type }) => ({
      id: accountId,
      display: formatFullName(firstName, lastName) ?? '',
      avatar,
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      type,
      roles: {
        ...roles,
        guest: roles.guest.filter((role) => role.boardId === boardId || ancestorsIds.includes(role.boardId)),
      },
    }));
  }, [ancestors, activeMembers, activeGuests, boardId]);

  return mentionMembers;
};
