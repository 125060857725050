import { Images } from '@air/next-icons';
import { useSelector } from 'react-redux';

import { CommentCount } from '~/components/Discussions/DiscussionsMenu/components/CommentCount';
import { DiscussionsMenuItem } from '~/components/Discussions/DiscussionsMenu/components/DiscussionsMenuItem';
import { centralizedClipLibraryCommentCountSelector } from '~/store/centralizedClip/selectors';

export const DiscussionsLibraryMenuItem = () => {
  const libraryCommentCount = useSelector(centralizedClipLibraryCommentCountSelector);

  return (
    <DiscussionsMenuItem
      adornmentRight={<CommentCount count={libraryCommentCount} />}
      thumbnail={
        <div className="flex h-full items-center justify-center bg-blue-3 text-blue-9">
          <Images className="size-5" />
        </div>
      }
      title="All assets & files"
    />
  );
};
