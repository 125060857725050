import { VolumeHigh, VolumeLow, VolumeMuted } from '@air/next-icons';
import { type DropdownMenuProps } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { Popover } from '@air/primitive-popover';
import { Slider } from '@air/primitive-slider';
import { memo, type MouseEvent, useCallback, useMemo, useRef, useState } from 'react';

import { VOLUME_BUTTON } from '~/constants/testIDs';

interface VolumeControlProps {
  volume: number;
  muted: boolean;
  toggleMuted: () => void;
  onVolumeChange: (value: number) => void;
  container: DropdownMenuProps['container'];
}

export const VolumeControl = memo(
  ({ volume = 0, muted, toggleMuted, onVolumeChange, container }: VolumeControlProps) => {
    const volumeControl = useRef<HTMLDivElement>(null);
    const [volumeControlVisible, setVolumeControlVisible] = useState(false);

    const showVolumeControl = () => setVolumeControlVisible(true);

    const hideVolumeControl = () => {
      setVolumeControlVisible(false);
    };

    const VolumeIcon = useMemo(() => {
      if (volume === 0 || muted) {
        return VolumeMuted;
      }

      return volume < 0.5 ? VolumeLow : VolumeHigh;
    }, [muted, volume]);

    const onVolumeButtonClick = useCallback(
      (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        toggleMuted();
      },
      [toggleMuted],
    );

    return (
      <div ref={volumeControl} className="relative" onMouseLeave={hideVolumeControl}>
        <Popover
          data-theme="dark"
          container={container}
          className="mb-1 ml-1 w-8 min-w-[32px] p-0"
          onOpenChange={setVolumeControlVisible}
          open={volumeControlVisible}
          sideOffset={0}
          trigger={
            <IconButton
              label="Volume"
              appearance="ghost"
              color="grey"
              size="large"
              data-testid={VOLUME_BUTTON}
              onClick={onVolumeButtonClick}
              onMouseEnter={showVolumeControl}
            >
              <VolumeIcon className="size-6" />
            </IconButton>
          }
        >
          <div className="flex items-center justify-center py-2">
            <Slider
              className="h-20"
              defaultValue={[volume]}
              formatTooltip={(values) => `${Math.round(values[0] * 100)}%`}
              orientation="vertical"
              onValueChange={(value) => onVolumeChange(value[0])}
              value={[volume]}
              step={0.01}
              max={1}
              min={0}
            />
          </div>
        </Popover>
      </div>
    );
  },
);

VolumeControl.displayName = 'VolumeControl';
