import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { memo } from 'react';

import { ANNOTATION_INDICATOR, COMMENT_METADATA_CONTAINER } from '~/constants/testIDs';
import { IconType } from '~/types/icons';

export const discussionMetadata = tailwindVariants({
  base: 'relative mr-1 inline-flex min-h-[12px] min-w-[12px]',
  variants: {
    color: {
      blue: 'text-blue-9',
      grey: 'text-grey-9',
    },
  },
  defaultVariants: {
    color: 'grey',
  },
});

export type DiscussionMetadataProps = VariantProps<typeof discussionMetadata> & {
  Icon: IconType;
  text?: string;
  textTestId?: string;
};

export const DiscussionMetadata = memo(({ color, text, Icon, textTestId }: DiscussionMetadataProps) => {
  return (
    <div className={discussionMetadata({ color })} data-testid={COMMENT_METADATA_CONTAINER}>
      {/* Weird bug with icon - I can not center it without using absolute position*/}
      <div className="absolute" data-testid={ANNOTATION_INDICATOR} style={{ top: !!text ? 3 : 0 }}>
        {!!Icon && <Icon className="size-3" />}
      </div>
      {!!text && (
        <div className="ml-4 text-14 text-inherit" data-testid={textTestId}>
          {text}
        </div>
      )}
    </div>
  );
});

DiscussionMetadata.displayName = 'DiscussionAnnotationInfo';
