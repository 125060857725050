import { Account } from '@air/api/types';
import { Avatar } from '@air/primitive-avatar';
import { CSSProperties, memo } from 'react';

import { TIMESTAMP_MARKER, TIMESTAMP_MARKER_AVATAR } from '~/constants/testIDs';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

import { MARKER_CONTAINER_WIDTH } from '../shared/constants';

export interface TimestampMarkerProps {
  userAvatar?: string;
  style?: CSSProperties;
  commenterId?: Account['id'];
  onClick: () => void;
  text?: string;
  count?: number;
}

export const TimestampMarker = memo(
  ({ userAvatar, style, onClick, text, commenterId, count = 1 }: TimestampMarkerProps) => {
    return (
      <button
        className="absolute bottom-1.5 flex flex-col items-center hover:z-1"
        style={{
          width: MARKER_CONTAINER_WIDTH,
          ...style,
        }}
        onClick={onClick}
        data-testid={TIMESTAMP_MARKER}
      >
        <div className="group/timestamp-marker relative">
          <Avatar
            data-testid={`${TIMESTAMP_MARKER_AVATAR}-${commenterId}`}
            className="pointer-events-none border border-peacock-300 group-hover/timestamp-marker:size-5 group-hover/timestamp-marker:border-white"
            size={16}
            src={getAvatarImageSrc(userAvatar, 16)}
            text={text}
            colorSeed={commenterId}
          />
          {count > 1 && (
            <div className="pointer-events-auto absolute bottom-0 left-0 flex size-4 items-center justify-center rounded-full border border-peacock-300 bg-pigeon-900/60 group-hover/timestamp-marker:size-5 group-hover/timestamp-marker:border-white">
              <div className="text-8 font-bold text-white">{count < 10 ? `+${count - 1}` : '9+'}</div>
            </div>
          )}
        </div>

        <div className="mt-1 h-1 w-0.5 bg-white" />
      </button>
    );
  },
);

TimestampMarker.displayName = 'TimestampMarker';
